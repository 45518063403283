<template>
  <div class="page">
    <div class="page-left">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          type="index"
          label="序号"
          width="55px"
          align="center"
        />
        <el-table-column prop="merchantName" label="商户名称" align="center" />
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="setData(scope.row)">设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-right">
      <div v-if="merchantNo">
        <div class="text">正在设置:{{ merchantName }}</div>

        <div style="margin-bottom: 10px">
          <el-radio-group v-model="radio">
            <el-radio-button label="1">通用设置</el-radio-button>
            <el-radio-button label="2">支付参数设置</el-radio-button>
          </el-radio-group>
        </div>
        <div v-if="radio == '1'" style="padding-right: 15px">
          <div class="text1">经营模式</div>
          <div class="flex-csb">
            <div>
              <div>
                <el-radio-group v-model="memberModel">
                  <el-radio :label="1">单店模式</el-radio>
                  <el-radio :label="2">商户模式</el-radio>
                  <el-radio :label="3">连锁品牌</el-radio>
                </el-radio-group>
                <span class="span">
                  注意：一旦门店拥有注册会员，则无法再进行切换
                </span>
              </div>
              <div class="text2">
                在此模式下各门店/档口独立经营，会员营销统一管理
              </div>
            </div>
            <el-button type="primary" @click="postMemberModel">保存</el-button>
          </div>
        </div>
        <div v-else>
          <!-- hmq -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="platformType == 'holumuch'"
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="机构ID（appId）">
              <el-input v-model="ruleForm.appId"></el-input>
            </el-form-item>
            <el-form-item label="子机构ID（subAppId）">
              <el-input v-model="ruleForm.subAppId"></el-input>
            </el-form-item>
            <el-form-item label="密钥（secretKey）">
              <el-input v-model="ruleForm.secretKey"></el-input>
            </el-form-item>
          </el-form>
          <!-- 长沙银行 -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="platformType == 'csb'"
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="商户ID/机构号（chmId）">
              <el-input v-model="ruleForm.chmId"></el-input>
            </el-form-item>
            <el-form-item label="子商户ID/商户ID（subChmId）">
              <el-input v-model="ruleForm.subChmId"></el-input>
            </el-form-item>
            <el-form-item label="私钥（priKey）">
              <el-input v-model="ruleForm.priKey"></el-input>
            </el-form-item>
            <el-form-item label="公钥（pubKey）">
              <el-input v-model="ruleForm.pubKey"></el-input>
            </el-form-item>
          </el-form>
          <!-- 国铁吉讯 -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="platformType == 'jixun'"
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="商户ID/机构号（chmId）">
              <el-input v-model="ruleForm.chmId"></el-input>
            </el-form-item>
            <el-form-item label="子商户ID/商户ID（subChmId）">
              <el-input v-model="ruleForm.subChmId"></el-input>
            </el-form-item>
            <el-form-item label="密钥（secretKey）">
              <el-input v-model="ruleForm.secretKey"></el-input>
            </el-form-item>
            <el-form-item label="商户编码（merchantCode/appId）">
              <el-input v-model="ruleForm.appId"></el-input>
            </el-form-item>
            <el-form-item label="店铺编码（appAuthToken/shopCode）">
              <el-input v-model="ruleForm.appAuthToken"></el-input>
            </el-form-item>
            <el-form-item label="私钥（priKey/serviceKey）">
              <el-input v-model="ruleForm.priKey"></el-input>
            </el-form-item>
            <el-form-item label="公钥（pubKey/secret）">
              <el-input v-model="ruleForm.pubKey"></el-input>
            </el-form-item>
          </el-form>
          <!-- 扫码富 -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="platformType == 'smf'"
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="平台机构号（chmId）">
              <el-input v-model="ruleForm.chmId"></el-input>
            </el-form-item>
            <el-form-item label="密钥（priKey）">
              <el-input v-model="ruleForm.priKey"></el-input>
            </el-form-item>
            <el-form-item label="签名key（pubKey）">
              <el-input v-model="ruleForm.pubKey"></el-input>
            </el-form-item>
            <el-form-item label="商户号（subChmId）">
              <el-input v-model="ruleForm.subChmId"></el-input>
            </el-form-item>
          </el-form>
          <!-- 邮储 -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="
              platformType == 'psbcSavings' || platformType == 'psbcDigiccy'
            "
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="系统ID:reqSysId">
              <el-input v-model="ruleForm.subAppId"></el-input>
            </el-form-item>
            <el-form-item label="机构号:platformID">
              <el-input v-model="ruleForm.chmId"></el-input>
            </el-form-item>
            <el-form-item label="商户号:mchtNo">
              <el-input v-model="ruleForm.subChmId"></el-input>
            </el-form-item>
            <el-form-item label="加签证书d密钥:sm2y">
              <el-input v-model="ruleForm.secretKey"></el-input>
            </el-form-item>
            <el-form-item label="数字证书:certNum">
              <el-input v-model="ruleForm.appAuthToken"></el-input>
            </el-form-item>
            <el-form-item label="验签证书x密钥:sm2x">
              <el-input v-model="ruleForm.priKey"></el-input>
            </el-form-item>
            <el-form-item label="验签证书y密钥:sm2y">
              <el-input v-model="ruleForm.pubKey"></el-input>
            </el-form-item>
          </el-form>
          <!-- psbcAllinpay -->
          <el-form
            label-position="right"
            label-width="300px"
            :model="ruleForm"
            v-if="platformType == 'psbcAllinpay'"
          >
            <el-form-item label="名称:商户名+支付">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="机构号(orgid):">
              <el-input v-model="ruleForm.chmId"></el-input>
            </el-form-item>
            <el-form-item label="商户号(cusid):">
              <el-input v-model="ruleForm.subChmId"></el-input>
            </el-form-item>
            <el-form-item label="商户appId(appId):">
              <el-input v-model="ruleForm.appId"></el-input>
            </el-form-item>
            <el-form-item label="sm2私钥(sm2pprivatekey):">
              <el-input v-model="ruleForm.priKey"></el-input>
            </el-form-item>
            <el-form-item label="sm2公钥(sm2tlpubkey):">
              <el-input v-model="ruleForm.pubKey"></el-input>
            </el-form-item>
          </el-form>
          <div class="flex-cc">
            <el-button type="primary" @click="preShopPayData">保 存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  queryMerchantListByMerCode,
  postMemberModel,
  getShopPayData,
  preShopPayData,
} from "@/api/product/index";
export default {
  data() {
    return {
      tableData: [],
      merchantNo: "",
      merchantName: "",
      radio: "1",
      memberModel: null,
      ruleForm: {
        appAuthToken: null,
        appId: null,
        chmId: null,
        merchantNo: null,
        priKey: null,
        pubKey: null,
        secretKey: null,
        subAppId: null,
        subChmId: null,
        type: null,
      },
      platformType: null,
    };
  },
  created() {
    this.queryMerchantListByMerCode();
  },
  methods: {
    preShopPayData() {
      if (this.platformType == "jixun") {
        this.ruleForm.subAppId = this.ruleForm.subChmId;
      }
      const data = {
        params: this.ruleForm,
      };
      preShopPayData(data).then((res) => {
          if(res.data.flag){
              this.$message.success('保存成功')
          }
      });
    },
    getShopPayData() {
      const data = {
        params: {
          merchantNo: this.ruleForm.merchantNo,
          type: this.ruleForm.type,
        },
      };
      getShopPayData(data).then((res) => {
        if (res.data.flag && res.data.data) {
          this.ruleForm = res.data.data;
        }
      });
    },
    postMemberModel() {
      let data = {
        params: {
          merchantNo: this.merchantNo,
          model: this.memberModel,
        },
      };
      postMemberModel(data).then((res) => {
        if (res.data.flag) {
          this.$message.success("保存成功");
          this.queryMerchantListByMerCode();
        }
      });
    },
    setData(e) {
      this.merchantNo = e.merchantNo;
      this.merchantName = e.merchantName;
      this.memberModel = e.memberModel;
      this.ruleForm.merchantNo = e.merchantNo;
      this.ruleForm.type = e.platformType;
      this.platformType = e.platformType;
      this.getShopPayData();
    },
    queryMerchantListByMerCode() {
      let data = {
        params: {
          merCode: JSON.parse(sessionStorage.getItem("LoginInfo")).merCode,
          merType: JSON.parse(sessionStorage.getItem("LoginInfo")).merType,
        },
      };
      queryMerchantListByMerCode(data).then((res) => {
        if (res.data.flag) {
          this.tableData = res.data.data;
        }
      });
    },
  },
};
</script>
<style scoped>
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page {
  display: flex;
  justify-content: flex-start;
}
.page-left {
  flex: 1;
  padding: 15px;
}
.page-right {
  flex: 2;
  padding: 15px 0;
}
.text {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.flex-csb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text1 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.span {
  color: red;
  margin-left: 10px;
}
.text2 {
  margin-top: 10px;
  font-size: 14px;
}
</style>